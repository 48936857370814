<template>
  <RedocWrapper
    :specOrSpecUrl="spec"
    @changeIsRedocLoaded="onChangeIsRedocLoaded"
  />
</template>

<script>
import { reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import yamljs from 'yamljs'

import { fetchApiDoc } from '@/api/apiDoc'
import RedocWrapper from '@/components/RedocWrapper'

export default {
  name: 'ApiDoc',
  components: {
    RedocWrapper,
  },
  setup() {
    const route = useRoute()
    const spec = reactive({})
    const { wallet, edition } = route.params

    const getApiDoc = async () => {
      const res = await fetchApiDoc({ wallet, edition })

      const { content } = res.data
      const contentToJson = yamljs.parse(content)
      Object.assign(spec, contentToJson)
    }

    const onChangeIsRedocLoaded = (value) => { if (value && window.opener) window.opener.postMessage({ isRedocLoaded: true }, '*') }

    onMounted(getApiDoc)

    return {
      spec,
      onChangeIsRedocLoaded,
    }
  },
}
</script>

<style>
@media print{
  @page {
    size: A4 landscape;
  }
  .platform-table {
    display: table!important;
    table-layout: fixed;
    font-size: 12px;
    overflow: hidden;
  }
  .platform-table th {
    height: 120px;
  }
  .platform-table tr th:first-child {
    width: 90px;
  }
  .vertical {
    width: 45px;
    transform: rotate(90deg) translateX(45px);
    transform-origin: top right;
  }
}
</style>
