import axios from 'axios'

export const fetchApiDoc = async ({ wallet, edition }) => {
  const res = await axios.post('/api/api-doc/getApiDoc', {
    walletType: wallet.replace(/-wallet/i, ''),
    edition,
  })

  return res.data
}

export const updateApiDoc = async (data) => {
  const res = await axios.post('/api/api-doc/updateApiDoc', data)

  return res.data
}
